import { BadgeLoginBlueIcon, BadgeSignUpBlueIcon } from "assests/Home";

export const NSTGatewayHeaderNonAuthLink = [
    {
        key: "0.1",
        page: "Home",
        className:"page-home",
        tab:"home",
        href:'/'
        
    },
    {
        key: "0.2",
        page: "Solution",
        className:"page-solution",
        tab:"solutions",
        href:'/merchantprocessingsolutions'
    },
    {
        key: "0.3",
        page: "Company",
        className:"page-company",
        tab:"company",
        href:'/company'
    },
    {
        key: "0.4",
        page: "Contact Us",
        className:"page-contact-us",
        tab:"contact_us",
        href:'/getstarted'

    },
    {
        key: "0.5",
        page: "Sign Up",
        Icon: (
            <>
                <BadgeSignUpBlueIcon />
            </>
        ),
        className:"page-signup",
        tab:"signup",
        href:'https://portal.nstgateway.com/registerUser.html'
    },
    {
        key: "0.6",
        page: "Login",
        Icon: (
            <>
                <BadgeLoginBlueIcon />
            </>
        ),
        className:"page-login",
        tab:"login",
        href:'https://portal.nstgateway.com'
    },
];