import React from 'react';
import { businessSolutionsContent } from 'content/businessSolutionsContent';
import { ArrowIcon } from 'assests/Home/index';
import ContentSubSection from './ContentSubSection';
import { Link } from 'react-router-dom';
import { subSectionsContent } from 'content/Home/contentSection';

export const ContentSection = () => {
    return (
        <div className="business-reg-dep-oper-sec">
            <div className='container'>
                <div className="business-reg-dep-oper-sec-top">
                    <h2 id="business-solutions-heading-title">
                        {businessSolutionsContent.heading.title}
                    </h2>
                    <p id="business-solutions-subtitle">
                        {businessSolutionsContent.heading.subtitle}
                    </p>
                </div>
                <div className="business-sreg-dep-oper-sec-bottom">
                    <div className="business-cards">
                        {subSectionsContent && (subSectionsContent || [])?.map((card, index) => (
                            <ContentSubSection
                                key={index}
                                wrapperId={card.wrapper}
                                FrameIcon={card.frameIcon}
                                headingTitle={card.headingTitle}
                                content={card.content}
                                useClassName={card.className}
                            />
                        ))}
                    </div>
                </div>

                <div className="get-started-btn-section">
                <Link className='get-started-btn' to='/merchantprocessingsolutions'> <span className='txt-btn'>Explore</span> <span className='MuiButton-endIcon'>
                <ArrowIcon /></span> </Link>
                    {/* <Button
                        className='get-started-btn'
                        endIcon={<ArrowIcon />}
                        onClick={() => navigate('/merchantprocessingsolutions')}
                    >Explore</Button> */}
                </div>
            </div>
        </div>
    );
};
