import Routes from './Routes';
import './App.css';

function App() {
  var div = document.querySelector("div") as HTMLDivElement;
  var resizeObserver = new ResizeObserver(entries => {
    resizeObserver.unobserve(div);
    resizeObserver.disconnect();
    requestAnimationFrame(() => resizeObserver.observe(div));
  });
  resizeObserver.observe(div);
  return (
    <>
      <Routes />
    </>
  )
}

export default App;
