import React from 'react';
import MeetTrustedPartnerImg from 'assests/Home/trusted-img.png';
import { Link } from 'react-router-dom';

const TrustedPartnerSection = () => {
    return (
        <section
            className="trusted-partner-main"

        >
            <div className='container'>
                <div className='trusted-partner-wrap'>
                <div className="business-trusted-partner-meet-img">
                    <img src={MeetTrustedPartnerImg} alt='MeetTrustedPartnerImg' />
                </div>
                <div className="trusted-partner-content-main-section">
                    <div className="trusted-partner-heading">
                        <h2>Trusted Partner</h2>
                    </div>
                    <div className="trusted-partner-content">
                        <p>Whatever your business,
                            <span> <strong>NST Gateway</strong> </span>
                            has the solution. With more than 20 years as a trusted payments provider,
                            <span> <strong>NST Gateway</strong> </span>
                            offers a variety of payment solutions for merchants of all sizes across industries.</p>
                    </div>
                    <div className="trusted-partner-cont-us-btn">
                        <div className="navigate-use-btn" >
                            <Link className='get-started-btn ' to='/getstarted'> Contact Us</Link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default TrustedPartnerSection;
