import React from 'react'
import BusinessFlexRightImg from 'assests/Home/business-img.png';
import BbbRtgLogo from "assests/Home/bbb_rtg_log.png"
import IncLogo from 'assests/Home/inc_logo.png'

export const Section01 = () => {
    return (
        <div className='nst-business-main'>
            <div className='container'>
                <div className="nst-bussiness-wrapper">

                    <div className="business-left">
                        <div className="main-business-view-img-upper">
                            <img src={BusinessFlexRightImg} alt='business_flex_img' />
                        </div>
                        <div className="flex-footer-business-addons-icon">
                            <span><img src={BbbRtgLogo} /></span>
                            <span><img src={IncLogo} /></span>
                        </div>
                    </div>
                    <div className="business-right">
                        <div className="bussines-grow-main-header-up">
                            <h2 id="grow-business-header">We Do Business Better</h2>
                        </div>
                        <div className="business-round-wrap-border-view-card">
                            <div className="business-round-wrap-content-inside">
                                <p id="business-round-wrap-content">NST Gateway is a successful entrepreneurial business that operates in a trustworthy manner. Partnering with us means that you're working with a merchant services company that values honesty and integrity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}