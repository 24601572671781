import React from 'react';
import './Company.css'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import GetStarted from 'components/GetStarted/GetStarted';

const CompanyPage = () => {
    return (
        <>
        <div  className='nst-gate-page'>
            <div className="company-container">
                <div className="company-content">
                    <h1 className="company-title">Small company team.</h1>
                    <h2 className="company-title">Large company capabilities.</h2>
                    <p className="company-subtitle">NST Gateway provides first-class support in real time, while delivering competitive, innovative products and services.</p>
                </div>
            </div>
            <div className="company-values-container">
                <div className="company-values-top">
                    <h1>Our Company Values</h1>
                </div>
                <div className="company-values-parts-container">
                    <div className="company-values-parts">
                        <div className="company-values-parts-div">
                            <div className="company-values-parts-icon"><AccountTreeIcon fontSize='large' /></div>
                            <div className="company-values-parts-content">
                                <h3>Integrity</h3>
                                <p>We believe people are the heart of every successful business. Our company may be forward-thinking with our tech, but we're old school with our support.</p>
                            </div>
                        </div>
                        <div className="company-values-parts-div">
                            <div className="company-values-parts-icon"><SettingsSuggestIcon fontSize='large' /></div>
                            <div className="company-values-parts-content">
                                <h3>Innovation</h3>
                                <p>Innovation is critical to growth so we leave no stone unturned in engineering faster, better solutions to help our Merchants and Partners grow their business.</p>
                            </div>
                        </div>
                        <div className="company-values-parts-div">
                            <div className="company-values-parts-icon"><HighlightAltIcon fontSize='large' /></div>
                            <div className="company-values-parts-content">
                                <h3>Accessibility</h3>
                                <p>From our platforms, to our products and private sales coaching, we're here to ensure you get the tools and support you need, when you need it.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-trusted-partner-container">
                <div className="company-trusted-partner-left-column">
                    <h2 className="company-trusted-partner-title">A Trusted Partner</h2>
                    <p className="company-trusted-partner-text">Payments made simple</p>
                    <p className="company-trusted-partner-text">We're business owners too so we know how important it is to get paid. Since 1997, we've helped thousands of businesses of all types and sizes accept credit cards easily, while keeping it affordable, secure and forward-thinking.</p>
                    <p className="company-trusted-partner-text">Our company values of integrity, innovation and accessibility can been felt throughout the company in the work we do for our merchants and our partners day in and day out.</p>
                </div>
                <div className="company-trusted-partner-right-column">
                    <img className="company-trusted-partner-image" src="NorthstarMedia/ContactUs/trusted_partner.png" alt="meeting_persons" />
                </div>
            </div>
            <div className="company-improvements-container">
                <div className="company-improvements-top">
                    <h2 className="company-improvements-title">Everything You Need to Run Your Business with Ease</h2>
                </div>
                <div className="company-improvements-bottom">
                    <div className="company-improvements-grid">
                    <p className="company-improvements-item"><span className="company-improvements-number">1.</span> Best-in-class tools</p>
                    <p className="company-improvements-item"><span className="company-improvements-number">2.</span> Personalized support</p>
                    <p className="company-improvements-item"><span className="company-improvements-number">3.</span> Proprietary technology</p>
                    <p className="company-improvements-item"><span className="company-improvements-number">4.</span> Value-added solutions</p>
                    </div>
                </div>
            </div>
            <div className='get-started-origin'>
                <GetStarted />
            </div>
            </div>
        </>
    );
}

export default CompanyPage;