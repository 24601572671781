import React from 'react';
import { Box, Divider, Drawer } from '@mui/material';
import { IconClose, LogoMainNSTGateway } from 'assests/Home';
import { NSTGatewayHeaderNonAuthLink } from 'content/headerContent';
import { NavLink } from 'react-router-dom';

interface HamburgerDrawerProps {
    open: boolean;
    toggleDrawer: (value: boolean) => void;
}

const HamburgerDrawer = ({ open, toggleDrawer }: HamburgerDrawerProps) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={(event, reason) => toggleDrawer(false)}
            PaperProps={{
                sx: { width: '300px' } 
            }}
        >
            <Box
                sx={{
                    p: 2,
                    height: '100%',
                    backgroundColor: 'white'
                }}
            >

                <div className="nst-gateway-hamburger-head-content">
                    <div className="nst-gateway-hamburger-app-main-logo">
                        <LogoMainNSTGateway />
                    </div>
                    <div className="nst-gateway-hamburger-drawer-icon-close" onClick={() => toggleDrawer(false)}>
                        <IconClose />
                    </div>
                </div>

                <Divider sx={{ mb: 2 }} />

                <Box>
                    {NSTGatewayHeaderNonAuthLink && NSTGatewayHeaderNonAuthLink.slice(0, -2).map((item) => {
                        return (
                            <NavLink
                                to={item.href}
                                key={item?.page}
                                rel='noopener noreferrer'
                                className={`navlink-header-redirect ${item?.className}`}
                                onClick={()=> toggleDrawer(false)}
                            >
                                {item?.page}
                                {item?.Icon && item?.Icon}
                            </NavLink>
                        );
                    })}
                </Box>
            </Box>
        </Drawer>
    );
};

export default HamburgerDrawer;
