import React, { useEffect, useState } from 'react';
import { NSTGatewayHeaderNonAuthLink } from 'content/headerContent';
import { AppBar, Box, Button, Container, Toolbar, Tooltip } from '@mui/material';
import { BadgeLoginBlueIcon, BadgeSignUpBlueIcon, IconHamburger, LogoMainNSTGateway } from 'assests/Home';
import HamburgerDrawer from './HamburgerDrawer';
import { Link, NavLink } from 'react-router-dom';
import 'components/Header/Header.css';
import useScrollToTop from 'utils/useScrollToTop';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { handleLogoClick } = useScrollToTop();

    const toggleDrawer = (value: boolean) => {
        setIsDrawerOpen(value);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const redirectToLoginSite = () => {
        window.open('https://portal.nstgateway.com', "_blank", "noreferrer");
        // window.location.href = 'https://portal.nstgateway.com';
    };
    const redirectToSignUpSite = () => {
        window.open('https://portal.nstgateway.com/registerUser.html', "_blank", "noreferrer");
        // window.location.href = 'https://portal.nstgateway.com/registerUser.html';
    };

    return (
        <AppBar className={`${isScrolled ? "scroll" : ''}`}>
            <Container>
                <Toolbar disableGutters>
                    <Link to='/' onClick={handleLogoClick}>
                        <LogoMainNSTGateway />
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {NSTGatewayHeaderNonAuthLink && (NSTGatewayHeaderNonAuthLink || [])?.map((item) => {
                            const isSignupOrLogin = item.tab === 'signup' || item.tab === 'login';
                            return (
                                <NavLink
                                    to={item.href}
                                    key={item?.page}
                                    target={isSignupOrLogin ? '_blank' : '_self'}
                                    rel='noopener noreferrer'
                                    className={`navlink-header-redirect ${item?.className}`}
                                >
                                    {item?.page}
                                    {item?.Icon && item?.Icon}
                                </NavLink>
                            );
                        })}
                    </Box>
                    <div className='nst-header-right'>
                        <div className="nst-gateway-on-page-scalling-auth">
                            <div className="nst-gateway-on-page-scalling-auth-btn-sign-up">
                                <Tooltip title="Sign Up" placement="top" arrow>
                                    <Button onClick={redirectToSignUpSite} startIcon={<BadgeSignUpBlueIcon />} />
                                </Tooltip>
                            </div>
                            <div className="nst-gateway-on-page-scalling-auth-btn-login">
                                <Tooltip title="Login" placement="top" arrow>
                                    <Button onClick={redirectToLoginSite} startIcon={<BadgeLoginBlueIcon />} />
                                </Tooltip>
                            </div>
                        </div>
                        <div onClick={() => toggleDrawer(true)} className="nst-gateway-header-icon-hamburger" >
                            <IconHamburger />
                        </div>
                    </div>

                </Toolbar>
            </Container>
            <HamburgerDrawer
                open={isDrawerOpen}
                toggleDrawer={toggleDrawer}
            />
        </AppBar>
    );
}

export default Header;