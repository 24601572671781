import React, { useState } from 'react';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';

const MainForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const validate = (name: string, value: string) => {
        let error = '';
        switch (name) {
            case 'name':
                if (!value) error = 'Name is required';
                break;
            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!value) {
                    error = 'Email is required';
                } else if (!emailRegex.test(value)) {
                    error = 'Enter a valid email';
                }
                break;
            case 'phone':
                const phoneRegex = /^[0-9]{10}$/;
                if (!value) {
                    error = 'Phone number is required';
                } else if (!phoneRegex.test(value)) {
                    error = 'Enter a valid 10-digit phone number';
                }
                break;
            case 'message':
                if (!value) error = 'Message is required';
                break;
            default:
                break;
        }
        return error;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const error = validate(name, value);
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: error,
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let hasErrors = false;
        let newErrors = { ...errors };

        // Validate all fields on submission
        Object.keys(formData).forEach((field) => {
            const error = validate(field, formData[field as keyof typeof formData]);
            if (error) hasErrors = true;
            newErrors = { ...newErrors, [field]: error };
        });

        setErrors(newErrors);

        if (!hasErrors) {
            console.log('Form submitted values:', formData);
        }
    };

    return (
        <>
            <div className="form-fill-main-input-fields">
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, mx: 'auto' }}>
                    <TextField
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        // helperText={errors.name}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        placeholder="Mail"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        // helperText={errors.email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        placeholder="Phone"
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={handleChange}
                        error={!!errors.phone}
                        // helperText={errors.phone}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocalPhoneRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        placeholder="Message"
                        name="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        error={!!errors.message}
                        // helperText={errors.message}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <ChatBubbleRoundedIcon />
                                </InputAdornment>
                            ),
                            sx: {
                                alignItems: "baseline"
                            },
                        }}
                    />
                    <div className="form-fill-submit-btn">
                        <div className="navigate-use-btn">
                            <Button type='submit'>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Box>
            </div>
        </>
    );
};

export default MainForm;
