import { FrameDeveloperFriendlyAPIIcon, FrameFlexibleAccessibilityIcon, FramePropertyGateway, FrameReliableSecurityIcon, FrameResponsiveSupportPaymentIcon } from "assests/Home";

export const subSectionsContentPayment = [
    {
        wrapper: "0.0.1",
        frameIcon: <FramePropertyGateway />,
        headingTitle: "Proprietary Gateway",
        className: "pay-sec-proprietary-gateway",
        content: (
            <>
                Our custom-built gateway ensures seamless transactions, optimized for speed and efficiency, tailored to your specific business needs
            </>
        )
    },
    {
        wrapper: "0.0.2",
        frameIcon: <FrameResponsiveSupportPaymentIcon />,
        headingTitle: "Responsive Support",
        className: "pay-sec-responsive-support",
        content: (
            <>
                We're a payments technology company who puts humans first, with support when you need it. You can count on us for real time support on the phone or online, with a detailed <span className='faq-link'>FAQ</span> and individual support
            </>
        )
    },
    {
        wrapper: "0.0.3",
        frameIcon: <FrameReliableSecurityIcon />,
        headingTitle: "Reliable Security",
        className: "pay-sec-reliable-security",
        content: (
            <>
                We prioritize the protection of your data and transactions with robust security measures, including encryption, fraud detection, and compliance with industry standards
            </>
        )
    },
    {
        wrapper: "0.0.4",
        frameIcon: <FrameFlexibleAccessibilityIcon />,
        headingTitle: "Flexible Accessibility",
        className: "pay-sec-flexible-accessibility",
        content: (
            <>
                Whether you're on a desktop, mobile device, or tablet, our platform is accessible anytime, anywhere, providing convenience and flexibility for both you and your customers
            </>
        )
    },
    {
        wrapper: "0.0.5",
        frameIcon: <FrameDeveloperFriendlyAPIIcon />,
        headingTitle: "Developer Friendly API",
        className: "pay-sec-developer-frd-api",
        content: (
            <>
                Our API is designed with developers in mind, offering comprehensive documentation, SDKs, and sandbox environments for easy integration and testing
            </>
        )
    },
];